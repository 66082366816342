<template>
  <div class="container-xl">
    <div class="card col-lg-12">
      <div class="card-header justify-content-between">
        <div class="card-title">EWA Invoice List <span v-if="$route.params.company_name">({{ $route.params.company_name }})</span></div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <el-button type="primary" @click="handlerGenerateInvoice" size="small"><font-awesome-icon icon="plus"/> Create Invoice</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <el-skeleton :loading="is_loading" :rows="6" animated />
        <table v-if="!is_loading" class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="15%">Invoicing Date</th>
              <th width="20%">Invoice Number</th>
              <th>Month</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Paid Date</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="is_loading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.created_at_str }}</td>
              <td>{{ item.invoice_number }}</td>
              <td>{{ item.month_str }}</td>
              <td>{{ item.amount_str }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>{{ item.paid_at_str }}</td>
              <td>
                <el-dropdown @command="handleCommand($event, item)" size="mini" type="info">
                  <el-button size="mini" type="info">
                    Action&nbsp;<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="px-2 py-2">
                    <div v-if="!item.overdue">
                      <el-dropdown-item class="py-1" command="handlerBtnViewInvoice">
                        <div class="flex">
                          <span style="width: 20px"><font-awesome-icon icon="eye"/></span>
                          <span>View Invoice</span>
                        </div>
                      </el-dropdown-item>
                    </div>
                    <div v-else>
                      <el-dropdown-item :disabled="true">View Invoice</el-dropdown-item>
                      <el-dropdown-item class="py-1" command="handlerBtnViewInvoiceNew">
                        <div class="flex">
                          <span style="width: 20px"><font-awesome-icon icon="eye"/></span>
                          <span class="w-4/5">New Invoice</span>
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item class="py-1" command="handlerBtnViewInvoice">
                        <div class="flex">
                          <span style="width: 20px"><font-awesome-icon icon="eye"/></span>
                          <span class="w-4/5">Old Invoice</span>
                        </div>
                      </el-dropdown-item>
                    </div>
                    <el-dropdown-item divided class="py-1" command="handlerDownloadInvoice">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="download"/></span>
                        <span>Download Invoice</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerBtnDetailInvoice">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="file-alt"/></span>
                        <span>View Detail</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerUpdateStatus">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="check"/></span>
                        <span class="w-3/4">Update Status</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="isInvoiceOverdue(item.due_date)" v-if="item.invoice_status === 'waiting_payment'" class="py-1" command="handlerRegenerateInvoice">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon :icon="['fas', 'sync-alt']" /></span>
                        <span class="w-4/5">Regenerate Invoice</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item :disabled="(!isInvoiceOverdue(item.due_date)) && item.invoice_status === 'waiting_payment'" v-if="item.invoice_status === 'waiting_payment'" class="py-1" command="handlerModal">
                      <div class="flex" @click="handlerModal(item.id)">
                        <span style="width: 20px"><font-awesome-icon :icon="['fas', 'sync-alt']" /></span>
                        <span class="w-4/5">Adjustment Invoice</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No invoice" v-if="!total_rows && !is_loading" :image="emptyStateImage"></el-empty>
      </div>
      <pagination
        :page="page"
        :per_page="per_page"
        :list="list"
        :total_rows="total_rows"
        @pageChange="pageChangeHandler"
        />
    </div>
    <b-modal size="lg" v-model="show.modal_invoice" title="Invoice Preview" hide-footer>
      <div class="w-100 h-100">
        <vue-pdf-embed :source="selected_invoice" />
      </div>
    </b-modal>
    <b-modal size="lg" v-model="show.generate_invoice" title="Create Invoice" hide-footer>
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="flex flex-row mb-2 pr-2 ml-2">
            <el-date-picker
              v-model="form.date"
              type="daterange"
              value-format="yyyy-MM-dd"
              size="small"
              class="w-full ml-2 pr-2"
              range-separator="To"
              start-placeholder="Start date"
              :clearable="false"
              end-placeholder="End date"
              :picker-options="datePickerOptions">
            </el-date-picker>
            <div class="ml-2 pr-2"> 
              <el-button class="mr-3" type="primary" @click="ewa.page=1, getInquiryInvoice(), onNext()" size="small">
                <font-awesome-icon icon="search"/> Check Invoice
              </el-button>
            </div>
            <el-button class="ml-2" type="primary" @click="handlerSendInvoice" size="small"><font-awesome-icon icon="file-invoice"/> Generate Invoice</el-button>
          </div>
        </div>
        <div class="table-responsive">
          <el-skeleton :loading="is_loading" :rows="6" animated />
          <table v-if="!is_loading" class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <!-- <th width="30%">Request Date</th> -->
                <th>Disbursement Date</th>
                <th width="30%">Requestor Name</th>
                <th>Amount</th>
                <th>Admin Fee</th>
                <th>HR Approval Status</th>
                <th>Disbursement Status</th>
                <th>Reference Number</th>
              </tr>
            </thead>
            <tbody v-loading="is_loading">
              <tr v-for="(item, i) in ewa.list" :key="i">
                <!-- <td>{{ item.created_at_str }}</td> -->
                <td>{{ item.disburse_at_str }}</td>
                <td>{{ item.requestor_name }}</td>
                <td>{{ item.request_amount_str }}</td>
                <td>{{ item.admin_fee_str }}</td>
                <td>
                  <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </td>
                <td>
                  <span class="tag" :class="item.disburse_status_color">{{ item.disburse_status_str }}</span>
                </td>
                <td>{{ item.reference_number || '-' }}</td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="!ewa.total_rows && !is_loading && !is_400_error" :image="emptyStateImage"></el-empty>
          <el-empty description="Sorry, the request took too long to process. Please try again later" v-else-if="is_400_error || is_timeout_error"></el-empty>
        </div>
        <pagination
          :page="ewa.page"
          :per_page="ewa.per_page"
          :list="ewa.list"
          :total_rows="ewa.total_rows"
          @pageChange="pageChangeHandlerEwa"
          />
    </div>
    </b-modal>
    <b-modal size="md" v-model="show.update_invoice" title="Update Invoice Status" hide-footer @onHide="resetForm">
      <p>You will update this invoice status. Please choose status for this invoice.</p>
      <b-form-group>
        <el-select v-model="v$.form_update.status.$model" class="w-full" size="small" placeholder="Choose invoice status">
          <el-option value="paid" label="Paid">Paid</el-option>
          <!-- <el-option value="cancelled" label="Cancelled">Cancelled</el-option> -->
        </el-select>
        <b-form-invalid-feedback :state="!v$.form_update.status.$invalid" v-if="v$.form_update.status.$errors[0]">
          <span> {{ v$.form_update.status.$errors[0].$message }}</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex float-right mt-2">
        <el-button @click="updateInvoiceStatus" size="small" type="primary" class="mr-2">Submit</el-button>
        <el-button @click="show.update_invoice = false; resetForm()" size="small" class="mr-2">Close</el-button>
      </div>
    </b-modal>
    <b-modal size="lg" title="Adjustment Invoice"  v-model="showModalSetlle" hide-footer>
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-form-group label="Amount Requested">
              <el-input :disabled="true" v-model="formattedAmount"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Current Penalty">
              <el-input :disabled="true" v-model="formattedPenalty"/>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Days">
              <el-input :disabled="true" :value="diffDays"/>
            </b-form-group>
          </b-col>
          <div class="w-100"></div>
          <b-col>
            <b-col>
              <b-form-group label="Total">
                <el-input :disabled="true" :value="formattedTotal"/>
              </b-form-group>
            </b-col>
          </b-col>
          <div class="w-100"></div>
          <b-col>
            <b-form-group label="Payment Date">
                <el-date-picker
                  v-model="form_adjustment.payment_date"
                  type="date"
                  value-format="yyyy-MM-dd"
                  size="small"
                  class="w-full ml-2 pr-2"
                  @change="validatePaymentDate"
                  placeholder="Select payment date">
                </el-date-picker>
                <span v-if="!isPaymentDateValid" class="text-danger">Payment date must be greater than the due date.</span>
            </b-form-group>
          </b-col>
          <b-col>
             <b-form-group label="Penalty Estimation">
                <el-input :disabled="true" :value="formattedPenaltyEstimation"/>
            </b-form-group>
          </b-col>
          <div class="w-100"></div>
          <b-col>
            <b-form-group label="Discount penalty fee">
               <el-input 
                  :readonly="isDiscountExceeding" 
                  placeholder="Input Discount penalty fee" 
                  v-model="formattedDiscountPenaltyFee" 
                  type="number"
                />
              <span v-if="isDiscountExceeding" class="text-danger">The Discount penalty fee value exceeds the Penalty Estimation value.</span>
              <span v-if="isDiscountNegative" class="text-danger">Discount penalty fee cannot be negative.</span>
          </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Payment Amount">
              <el-input :disabled="true" v-model="formattedPaymentAmount"/>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mb-4 w-full">
      </b-container>
      <div class="d-flex flex-row float-right">
        <el-button @click="handlerSettleOverDueInvoice" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
        <el-button @click="closeModalAdjustment" size="small" class="mr-2">Cancel</el-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  required,
} from '@vuelidate/validators';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import moment from 'moment';
import {
  INVOICE_LIST, INVOICE_GENERATE, GET_DETAIL_EWALIST, INVOICE_UPDATE_STATUS, INVOICE_INQUIRY, INVOICE_REGENERATE, SETTLE_OVER_DUE_INVOICE
} from '@/store/modules/ewa';
import emptyStateImage from '@/assets/images/undraw/empty-approval-history.svg';
import Pagination from '../../components/Pagination.vue';
import popupErrorMessages from '../../../library/popup-error-messages';
import url from 'url';

const invoice_status = {
  waiting_payment: {
    label: 'Waiting Payment',
    color: 'tag-warning',
  },
  partial_paid: {
    label: 'Partial Paid',
    color: 'tag-info',
  },
  paid: {
    label: 'Paid',
    color: 'tag-success',
  },
  cancelled: {
    label: 'Cancelled',
    color: 'tag-danger',
  },
};

const ewa_disburse_status = {
  waiting_approval: {
    label: 'Waiting Approval',
    color: 'tag-warning',
  },
  waiting_disbursement: {
    label: 'Waiting Disbursement',
    color: 'tag-brand',
  },
  rejected: {
    label: 'Rejected',
    color: 'tag-danger',
  },
  completed: {
    label: 'Success',
    color: 'tag-success',
  },
  failed: {
    label: 'Failed',
    color: 'tag-danger',
  },
};

const ewa_status = {
  waiting_approval: {
    label: 'Waiting Approval',
    color: 'tag-warning',
  },
  rejected: {
    label: 'Rejected',
    color: 'tag-danger',
  },
  approved: {
    label: 'Approved',
    color: 'tag-success',
  },
};

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form_update: {
        status: { required },
      },
    };
  },
  components: {
    Pagination,
    VuePdfEmbed,
  },
  name: 'InvoiceListEwa',
  data() {
    return {
      is_400_error: false,
      isDiscountNegative: false, 
      isPaymentDateValid: true,
      isDiscountExceeding: false, 
      selectedInvoice: null,
      selectedCompanyId: null,
      selectedAmount: null,
      selectedDueDate: null,
      selectedOverDue: null,
      showModalSetlle: false,
      is_timeout_error: false,
      emptyStateImage,
      is_loading: false,
      total_rows: 0,
      ewa: {
        page: 1,
        total_rows: 0,
        list: [],
        per_page: 20,
      },
      form: {
      date: [
            moment().subtract(30, 'day').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD'),
      ],
      start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      form_adjustment: {
        payment_date: '',
        payment_amount: '',
        discount_penalty_fee: '',
      },
      page: 1,
      per_page: 20,
      list: [],
      show: {
        modal_invoice: false,
        generate_invoice: false,
        update_invoice: false,
      },
      form_update: {
        status: '',
      },
      loading: {
        modal_invoice: false,
      },
      selected_ewa: {},
      status_filter: '',
      disbursement_status_filter: '',
      selected_invoice: {},
      search: {
        search_keyword: '',
        search_by: 'full_name',
        date: [
          moment().subtract(1, 'month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        action: '',
      },
      search_options: [
        { value: 'full_name', text: 'Requestor Name' },
        { value: 'email', text: 'Requestor Email' },
        { value: 'phone_number', text: 'Requestor Phone Number' },
      ],
    };
  },
  metaInfo: {
    title: 'Early Wage Access',
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
    formattedDiscountPenaltyFee: {
      get() {
        return this.formatNumber(this.form_adjustment.discount_penalty_fee);
      },
      set(value) {
        this.form_adjustment.discount_penalty_fee = this.parseFormattedNumber(value);
      },
    },
    formattedTotal: {
      get() {
        return this.formatNumber(this.total);
      },
      set(value) {
        this.total = this.parseFormattedNumber(value);
      },
    },
    formattedPaymentAmount: {
      get() {
        return this.formatNumber(this.paymentAmount);
      },
    },
    formattedAmount: {
      get() {
        return this.formatNumber(this.selectedAmount);
      },
      set(value) {
        this.selectedAmount = this.parseFormattedNumber(value);
      },
    },
    formattedPenalty: {
      get() {
        return this.formatNumber(this.penalty);
      },
      set(value) {
        this.selectedAmount = this.parseFormattedNumber(value);
      },
    },
    penalty() {
      if (!this.selectedDueDate || !this.selectedAmount) {
        return 0;
      }
      const dueDate = new Date(this.selectedDueDate);
      const today = new Date();
      dueDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      const diffTime = Math.max(today - dueDate, 0);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(`Total hari: ${diffDays}`);
      return diffDays * this.basePenalty;
    },
    formattedPenaltyEstimation: {
      get() {
        return this.formatNumber(this.penaltyEstimation);
      },
    },
    basePenalty() {
      return 0.0013 * this.selectedAmount;
    },
    isDiscountPenaltyFeeValid() {
      return this.form_adjustment.discount_penalty_fee <= this.penaltyEstimation;
    },
    day() {
      if (!this.selectedDueDate || !this.selectedAmount) {
        return 0;
      }
      const dueDate = new Date(this.selectedDueDate);
      const today = new Date();
      dueDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      const diffTime = Math.max(today - dueDate, 0); 
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(`Total hari: ${diffDays}`);  
      return diffDays * 0.0013 * this.selectedAmount;
    },
    total() {
      const penaltyValue = this.penalty;
      const selectedAmount = this.selectedAmount || 0;
      return selectedAmount + penaltyValue;
    },
    diffDays() {
      if (!this.selectedDueDate) {
        return 0;
      }
      const dueDate = new Date(this.selectedDueDate);
      const today = new Date();
      dueDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      const diffTime = Math.max(today - dueDate, 0);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    penaltyEstimation() {
      if (!this.selectedDueDate || !this.selectedAmount || !this.form_adjustment.payment_date) {
        return 0;
      }
      console.log('selectedDueDate:', this.selectedDueDate);
      console.log('selectedAmount:', this.selectedAmount);
      console.log('form_adjustment.payment_date:', this.form_adjustment.payment_date);
      const dueDate = new Date(this.selectedDueDate);
      const paymentDate = new Date(this.form_adjustment.payment_date);
      dueDate.setHours(0, 0, 0, 0);
      paymentDate.setHours(0, 0, 0, 0);
      const diffTime = Math.max(paymentDate - dueDate, 0); 
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log(`Total days for penalty estimation: ${diffDays}`);
      return diffDays * this.basePenalty;
    },
    paymentAmount() {
      const penaltyEstimationValue = this.penaltyEstimation;
      const selectedAmount = this.selectedAmount || 0;
      const selectDiscPenalty = this.form_adjustment.discount_penalty_fee
      return selectedAmount + penaltyEstimationValue - selectDiscPenalty;
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getInquiryInvoice() {
      try {     
        this.is_loading = true;
        this.$parent.showLoader();
        const { data } = await this.$store.dispatch(INVOICE_INQUIRY, {
          id: this.$route.params.id,
          data: {
            start_date: this.form.start_date,
            end_date: this.form.end_date,
            page: this.ewa.page,
          },
        });
        const { rows, count } = data;
        this.ewa.list = count ? rows.map((v) => {
          v.disburse_at_str = moment(v.disburse_at).format('DD MMM YYYY, HH:mm');
          v.requestor_name = v.requestor.full_name;
          v.status_str = ewa_status[v.status].label;
          v.status_color = ewa_status[v.status].color;
          if (v.status === 'rejected') {
            v.disburse_status_str = '-';
            v.disburse_status_color = 'tag-default';
          } else {
            v.disburse_status_str = ewa_disburse_status[v.disburse_status].label;
            v.disburse_status_color = ewa_disburse_status[v.disburse_status].color;
          }
          v.request_amount_str = `Rp. ${v.request_amount ? v.request_amount.toLocaleString() : 0}`;
          v.admin_fee_str = `Rp. ${v.admin_fee ? v.admin_fee.toLocaleString() : 0}`;
          return v;
        }) : [];
        this.ewa.total_rows = count;
        this.$parent.hideLoader();
        this.is_loading = false;
        this.is_400_error = false;
        this.is_timeout_error = false;
        } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            // 400
            this.is_400_error = true;
          } else if (error.response.data && error.response.data.error && error.response.data.error.code === 1021) {
            // time out
            this.is_timeout_error = true;
          }
        }
        this.is_loading = false;
        this.$parent.hideLoader();
      }
    },
    async getList() {
      this.is_loading = true;
      this.$parent.showLoader();
    try {
      const { data } = await this.$store.dispatch(INVOICE_LIST, {
        id: this.$route.params.id,
        query: {
          page: this.page,
        },
      });
      this.list = data.count ? data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        let displayedAmount = v.amount;
        if (v.overdue && v.overdue.length > 0) {
          const sortedOverdue = v.overdue.slice().sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          displayedAmount = sortedOverdue[0].payment_amount;
        }
        v.amount_str = `Rp. ${displayedAmount ? displayedAmount.toLocaleString() : 0}`;
        const status = invoice_status[v.invoice_status] || { label: '-', color: 'grey' }; 
        v.status_str = status.label;
        v.status_color = status.color;
        v.month_str = moment(v.invoice_month, 'YYYY-MM').format('MMMM YYYY');
        v.paid_at_str = v.paid_at ? moment(v.paid_at).format('DD MMM YYYY') : '-';
        return v;
      }) : [];
      this.total_rows = data.count;
      } catch (error) {
        console.error('Error fetching invoice list:', error);
      } finally {
        this.is_loading = false;
      this.$parent.hideLoader();
      }
    },
    // test 2 condition
    // async getList() {
    //   this.is_loading = true;
    //   this.$parent.showLoader();
    //   try {
    //     const { data } = await this.$store.dispatch(INVOICE_LIST, {
    //       id: this.$route.params.id,
    //       query: {
    //         page: this.page,
    //       },
    //     });
    //     let rows = [];
    //     let totalRows = 0;
    //     if (data.rows && data.rows.length > 0) {
    //       const firstRow = data.rows[0];
    //       if (firstRow.rows && firstRow.rows.length > 0) {
    //         rows = firstRow.rows;
    //         totalRows = firstRow.count;
    //       } else {
    //         rows = data.rows; 
    //         totalRows = data.count;
    //       }
    //     }
    //     this.list = rows.map((v) => {
    //       v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');

    //       let displayedAmount = v.amount;
    //       if (v.overdue && v.overdue.length > 0) {
    //         const sortedOverdue = v.overdue.slice().sort((a, b) => {
    //           return new Date(b.created_at) - new Date(a.created_at);
    //         });
    //         displayedAmount = sortedOverdue[0].payment_amount;
    //       }
    //       v.amount_str = `Rp. ${displayedAmount ? displayedAmount.toLocaleString() : 0}`;
    //       const status = invoice_status[v.invoice_status] || { label: 'Unknown', color: 'grey' };
    //       v.status_str = status.label;
    //       v.status_color = status.color;
    //       v.month_str = moment(v.invoice_month, 'YYYY-MM').format('MMMM YYYY');
    //       v.paid_at_str = v.paid_at ? moment(v.paid_at).format('DD MMM YYYY') : '-';
    //       return v;
    //     });
    //     this.total_rows = totalRows; 
    //   } catch (error) {
    //     console.error('Error fetching invoice list:', error);
    //   } finally {
    //     this.is_loading = false;
    //     this.$parent.hideLoader();
    //   }
    // },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.search.action = '';
      this.pageChangeHandler(1);
    },
    downloadHandler() {
      this.search.action = 'download';
      this.pageChangeHandler(1);
    },
    handlerBtnViewInvoice(item) {
      this.show.modal_invoice = true;
      this.selected_invoice = url.resolve(process.env.VUE_APP_VDASHBOARD_API_URL, `api/render?url=${item.invoice_url}`);
    },
    handlerBtnViewInvoiceNew(item) {
      const lastOverdue = item.overdue[item.overdue.length - 1];
      if (lastOverdue && lastOverdue.invoice_url) {
        this.show.modal_invoice = true;
        this.selected_invoice = url.resolve(
          process.env.VUE_APP_VDASHBOARD_API_URL,
          `api/render?url=${lastOverdue.invoice_url}`
        );
      } else {
        console.error("No overdue invoice URL found.");
      }
    },
    async handlerDownloadInvoice(item) {
      this.$parent.showLoader();
      const invoiceUrl = item.overdue && item.overdue.length > 0 
        ? item.overdue[item.overdue.length - 1].invoice_url 
        : item.invoice_url;
      const split_name = invoiceUrl.split(/\//g);
      const file_name = split_name[split_name.length - 1];
      await fetch(url.resolve(process.env.VUE_APP_VDASHBOARD_API_URL, `api/render?url=${invoiceUrl}`)).then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file_name;
          link.click();
        });
      this.$parent.hideLoader();
    },
    async handlerRegenerateInvoice(item) {
      this.$parent.showLoader();
      await this.$store.dispatch(INVOICE_REGENERATE, {
        id: item.id
      });
      this.$parent.hideLoader();
    },
    async handlerSettleOverDueInvoice() {
      if (!this.selectedInvoice) return;
      this.form_adjustment.discount_penalty_fee = parseInt(this.form_adjustment.discount_penalty_fee);
      this.form_adjustment.payment_amount = parseInt(this.form_adjustment.payment_amount);
      this.$parent.showLoader();
      try {
        const requestBody = {
          id: this.selectedInvoice.id,
          data: {
            ewa: {
              ewa_invoice_id: this.selectedInvoice.id,
              invoice_number: this.selectedInvoice.invoice_number,
              end_date: this.selectedInvoice.end_date,
              due_date: this.selectedInvoice.due_date,
              amount: this.selectedInvoice.amount,
              company_id: this.selectedInvoice.company_id,
              discount_penalty_fee: this.form_adjustment.discount_penalty_fee,
              overdue:  this.selectedInvoice.overdue
            },
            payment_date: this.form_adjustment.payment_date,
            // payment_amount: this.form_adjustment.payment_amount,
            payment_amount: this.paymentAmount,
          }
        };
        await this.$store.dispatch(SETTLE_OVER_DUE_INVOICE, requestBody);
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Regenerate invoice sent successfully',
        });
      } catch (error) {
        console.error('Error settling overdue invoice:', error);
      } finally {
        this.$parent.hideLoader();
        this.closeModalAdjustment();
      }
    },
    validatePaymentDate() {
      if (!this.selectedDueDate || !this.form_adjustment.payment_date) {
        this.isPaymentDateValid = true;
        return;
      }
      const dueDate = new Date(this.selectedDueDate);
      const paymentDate = new Date(this.form_adjustment.payment_date);
      this.isPaymentDateValid = paymentDate > dueDate;
    },
    closeModalAdjustment() {
      this.showModalSetlle = false,
      this.form_adjustment = {
        discount_penalty_fee: '',
        payment_amount: '',
        payment_date: '',
      };
    },
    handlerModal(id) {
      this.showModalSetlle = true;
      // console.log('test dropdown', id);
      const selected = this.list.find(item => item.id === id);
      if (selected) {
        this.selectedInvoice = selected;
        this.selectedCompanyId = selected.company_id;
        // console.log('Selected Invoice:', this.selectedInvoice);
        // console.log('Selected Company ID:', this.selectedCompanyId);
        this.selectedAmount = selected.amount;
        this.selectedDueDate = selected.due_date;
        this.selectedOverDue = selected.overdue;
        this.showModalSetlle = true;
      }
      this.showModalSetlle = true;
    },
    isInvoiceOverdue(dueDate) {
      const now = new Date();
      const due = new Date(dueDate);
      return now > due;
    },
    handlerGenerateInvoice() {
      this.form.date = null;
      this.ewa.list = [];
      this.show.generate_invoice = true;
      // this.getInquiryInvoice();
    },
    async getEWAList() {
      this.is_loading = true;
      this.$parent.showLoader();
      await this.$store.dispatch(GET_DETAIL_EWALIST, {
        id: this.$route.params.id,
        query: {
          disburse_status_filter: 'completed',
          status_filter: 'approved',
          page: this.ewa.page,
          disburse_date: [
            moment(this.form.start_date, 'YYYY-MM-DD').startOf('start_date').format(),
            moment(this.form.end_date, 'YYYY-MM-DD').endOf('end_date').format(),
          ],
          order_by: 'disburse_at',
          order_type: 'desc',
        },
      });
      const { rows, count } = this.$store.getters.detail_ewa_list;
      this.ewa.list = count ? rows : [];
      this.ewa.total_rows = count;
      this.$parent.hideLoader();
      this.is_loading = false;
    },
    pageChangeHandlerEwa(page) {
      this.ewa.page = page;
      this.getInquiryInvoice();
    },
    handlerSendInvoice() {
      this.$confirm('You will generate new invoice. Old invoice on same month will be marked as cancelled. Continue?', 'Confirmation', {
        type: 'info',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(INVOICE_GENERATE, {
              id: this.$route.params.id,
              data: {
                start_date: this.form.start_date,
                end_date: this.form.end_date,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Invoice sent to client',
              });
              this.show.generate_invoice = false;
              // this.getList();
            }).catch((err) => {
              if (err.response?.data) popupErrorMessages(err.response.data);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {});
    },
    handlerBtnDetailInvoice(item) {
      console.log(item);
      this.$router.push({
        name: 'EWA Invoice Detail',
        params: {
          id: this.$route.params.id,
          invoice_id: item.id,
        },
      });
    },
    handleCommand(action, item) {
      this[action](item);
    },
    resetForm() {
      this.form_update = {
        invoice_id: '',
        status: '',
      };
    },
    handlerUpdateStatus(item) {
      this.form_update.invoice_id = item.id;
      this.v$.form_update.$touch();
      this.show.update_invoice = true;
    },
    updateInvoiceStatus() {
      this.v$.form_update.$touch();
      if (this.v$.form_update.$error) return;
      this.$confirm('Are you sure want to update this invoice status?', 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(INVOICE_UPDATE_STATUS, {
              company_id: this.$route.params.id,
              invoice_id: this.form_update.invoice_id,
              data: {
                status: this.form_update.status,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Successfully update invoice status',
              });
              this.show.update_invoice = false;
              this.getList();
              this.resetForm();
            }).catch((err) => {
              popupErrorMessages(err);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      });
    },
    onNext() {
      this.page++;
      this.$emit('page', this.page); 
    },
    formatNumber(value) {
      if (!value) return '';
      value = value.toString().replace(/\D/g, '');
      return parseInt(value, 10).toLocaleString('id-ID');
    },
    parseFormattedNumber(value) {
      if (!value) return '';
      return value.toString().replace(/\./g, '');
    },
  },
  watch: {
    'form.date'(newDateRange) {
      this.form.start_date = newDateRange[0];
      this.form.end_date = newDateRange[1];
    },
    'form_adjustment.discount_penalty_fee': function (newValue) {
      if (newValue > this.penaltyEstimation) {
        this.isDiscountExceeding = true;
        this.form_adjustment.discount_penalty_fee = this.penaltyEstimation;
      } else {
        this.isDiscountExceeding = false;
      }
    },
    'form_adjustment.payment_date': function (newValue) {
      this.validatePaymentDate();
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}
</style>
